import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { SectionThree } from "../../components/pageSection/home/SectionThree";
import { useTranslation } from 'react-i18next';
import { TopComponent } from "../../components/TopComponents";
import backgroundImage from "../../assets/img/hero-bg.jpg";

export const Service = () => {
  const { t } = useTranslation();
  const mainRef = useRef(null);

  useEffect(() => {
    if (mainRef.current) {
      mainRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <>
      <main id="main" ref={mainRef}>
      <TopComponent title={t("shërbimet")} backgroundImage={backgroundImage} />
        <SectionThree/>
      </main>
    </>
  );
};
