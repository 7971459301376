import React from "react";
import { Link } from "react-router-dom";
import backgroundImage from "../assets/img/hero-bg.jpg"
import { useTranslation } from "react-i18next";

export const TopComponent = ({ title, backgroundImage }) => {
  const { t } = useTranslation();

  const style = {
    backgroundImage: `url(${backgroundImage})`,
  };

  return (
    <div className="breadcrumbs d-flex align-items-center" style={style}>
      <div className="container position-relative d-flex flex-column align-items-center">
        <h2>{title}</h2>
        <ol>
          <li>
            <Link to="/">{t("ballina")}</Link>
          </li>
          <li>{title}</li>
        </ol>
      </div>
    </div>
  );
};
