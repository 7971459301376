import React from 'react';
import plan from "../../../assets/img/about-plan.jpg"
import vision from "../../../assets/img/about-vision.jpg"
import mission from "../../../assets/img/about-mission.jpg"
import { useTranslation } from 'react-i18next';

function HelloSection() {
  const { t } = useTranslation();
  return (
    <section id="about">
      <div className="container" data-aos="fade-up">
        <header className="section-header">
        <div className="section-header">
            <h2>{t("rrethNesh")}</h2>
          </div>
        </header>
        <div className="row about-cols">
          <div className="col-md-4" data-aos="fade-up" data-aos-delay="100">
            <div className="about-col">
              <div className="img">
                <img src={mission} alt="" className="img-fluid" />
                <div className="icon">
                  <i className="bi bi-bar-chart"></i>
                </div>
              </div>
              <h2 className="title">
                <a href="#">{t("misioniyne")}</a>
              </h2>
              <p>
                {t("misioni")}
              </p>
            </div>
          </div>
          <div className="col-md-4" data-aos="fade-up" data-aos-delay="200">
            <div className="about-col">
              <div className="img">
                <img src={plan} alt="" className="img-fluid" />
                <div className="icon">
                  <i className="bi bi-brightness-high"></i>
                </div>
              </div>
              <h2 className="title">
                <a href="#">{t("planiyne")}</a>
              </h2>
              <p>
                {t("plani")}
              </p>
            </div>
          </div>
          <div className="col-md-4" data-aos="fade-up" data-aos-delay="300">
            <div className="about-col">
              <div className="img">
                <img src={vision} alt="" className="img-fluid" />
                <div className="icon">
                  <i className="bi bi-calendar4-week"></i>
                </div>
              </div>
              <h2 className="title">
                <a href="#">{t("visioniyne")}</a>
              </h2>
              <p>
                  {t("visioni")}
              </p>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HelloSection;
