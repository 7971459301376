import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import Form from 'react-bootstrap/Form';


export const ChangeButton = () => {
    const { t } = useTranslation();
    function changeLanguage(event) {
        const languageCode = event.target.value;
        i18n.changeLanguage(languageCode);
    }
    return(
        <Form>
            <Form.Group controlId="languageSelect">
                <Form.Control as="select" onChange={changeLanguage} className="form-control my-dropdown">
                    <option value="sq">{t('SQ')}</option>
                    <option value="en">{t('EN')}</option>
                    <option value="de">{t('DE')}</option>
                </Form.Control>
            </Form.Group>
        </Form>
    )
};
