import React from "react";
import { portofolioItems } from "../../../data/portofolio";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
export const Projects = () => {
  const { t } = useTranslation()

  return (
    <Row className="gy-4 px-5">
      {portofolioItems.map((item, index) => (
        <Col lg={4} md={6} key={index} className="portfolio-item">
          <div className="portfolio-wrap">
            <img src={item.img} className="img-fluid" alt={item.title} />
            <div className="portfolio-info">
              <h4>{item.title}</h4>
              <p className="portfolio-item-description">  {t(item.description)}</p>
              <Link to={item.link} className="link-details" title="link">
                {item.link}
              </Link>
              <Link to={`/project/${item.id}`}>
                <FontAwesomeIcon icon={faAnglesRight} />
              </Link>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  )
}