import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router";
import { TopComponent } from "../../components/TopComponents";
import { portofolioItems } from "../../data/portofolio";
import { useTranslation } from "react-i18next";
const Project = () => {
  const { t } = useTranslation();
  const params = useParams()
  const [news, setnews] = useState(null)

  //ekzekutohet 
  //1. kur hapet componenta 
  //2. kur behet udpate new state 
  //3. kur unmount componenta

  // TODO: do something
  useEffect(() => {
    if (news === null) {
      const newsFiltered = portofolioItems.filter(item => item.id === Number(params.id));
      setnews(newsFiltered[0]);
    }
  }, [params.id]);

  return (
    <main id="main">
      <TopComponent title={t("detaje")} />
      {news ? (
        <section id="portfolio-details" className="portfolio-details">
          <Container data-aos="fade-up">
            <Row className="gy-4">
              <Col lg={8}>
                <div className="slides-1 portfolio-details-slider swiper">
                  <div className="swiper-wrapper align-items-center">
                    <div className="swiper-slide">
                      <img
                        src={news.img}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="portfolio-info">
                  <h3>{t("përmbajtjaProjektit")}</h3>
                  <ul>
                    <li>
                      <strong>{t("klienti")} </strong>{news.title}
                    </li>
                    {/* <li>
                      <strong>{t("dataProjektit")}</strong>: 01 Mars, 2020
                    </li> */}
                    <li>
                      <strong>{t("linku")}</strong>:{" "}
                      <a href={news.link}>
                        {news.link}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="portfolio-description">
                  <h2>{t("pershkrimiProjektit")}</h2>
                  <p>
                    {t(news.description)}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      ) : (
        <p>Loading...</p>
      )}
    </main>
  );
};

export default Project;
