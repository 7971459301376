import React from "react";
import { Link } from "react-router-dom";

import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from "react-bootstrap";

export const SectionFour = () => {
    const { t } = useTranslation();
    return(
<section id="call-to-action" className="call-to-action">
<Container data-aos="fade-up">
  <Row className="justify-content-center">
    <Col lg={6} className="text-center">
      <h3>{t("zgjidhjeShpejte")}</h3>
      <p>
        {t("vendiDuhur")}
      </p>
      <Link className="cta-btn" to="/contact ">
        {t("ContactUs")}
      </Link>
    </Col>
  </Row>
</Container>
</section>
    )
}