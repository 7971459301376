import React from "react";
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export const SectionOne = () =>{
    const { t } = useTranslation();
    return (
      <section id="hero" className="hero d-flex align-items-center">
      <Container>
          <Row>
              <Col xl={4} className="above-everything" data-aos="fade-up">
                  <h2>{t("zgjidhjeUnike")}</h2>
                  <blockquote data-aos="fade-up" data-aos-delay="100">
                      <p>{t("ekipP")}</p>
                  </blockquote>
                  <div className="d-flex" data-aos="fade-up" data-aos-delay="200">
                      <Link to="/contact" className="btn-get-started">
                          {t("kontaktoE")}
                      </Link>
                  </div>
              </Col>
          </Row>
      </Container>
  </section>
    );
}
export default SectionOne;