import React from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import sigma from "../../../assets/img/sigma.png"
import appware from "../../../assets/img/appware.png"


const partners = [
  {
    img: 'https://crosslab.ch/assets/img/logos/just-logo.png',
    link: 'https://crosslab.ch/',
    name:'CROSSLAB'
  },
  {
    img: appware,
    link: 'https://www.appware.ch/de/',
    name:'APPWARE'

  },
  {
    img: 'https://yf-europa.de/wp-content/uploads/2022/10/Logo-YFE-Kopie-300x300-1.png',
    link: 'https://www.yf-europa.de',
    name:'YF EUROPA'

  },
  {
    img: sigma,
    link: 'https://www.sigmaweb.org/',
    name:'SIGMA'

  },
]
export const Partners = () => {
  return (
    <Container>
      <Row className="justify-content-center">
        <div className="d-flex flex-wrap" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
          {partners.map((item, index) => (
            <Link key={index} target="_blank" to={item.link} className="col-xl-2 col-md-4 col-6">
              <div className="card-bg img-container">
                <div className="partner-content">
                  <img className="img-thumbnail" src={item.img} alt={item.name} />
                  <p className="partner-text">{item.name}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </Row>
    </Container>
  );
};
