import React from "react";
import { useTranslation } from 'react-i18next';
import { Container, Row , Col} from "react-bootstrap";

export const SectionTwo = () => {
    const { t } = useTranslation();
    return(
      <section id="why-us" className="why-us">
      <Container data-aos="fade-up">
          <div className="section-header">
              <h2>{t("pseVisualCore")}</h2>
          </div>
          <Row className="g-0" data-aos="fade-up" data-aos-delay="200">
              <Col xl={5} className="img-bg"></Col>
              <Col xl={7} className="slides position-relative">
                  <div className="slides-1 swiper">
                      <div className="swiper-wrapper">
                          <div className="swiper-slide">
                              <div className="item">
                                  <h3 className="mb-3">{t("punaProfesionale")}</h3>
                                  <h4 className="mb-3">{t("mbi5")}</h4>
                                  <p>{t("gjuhetAvancuara")}</p>
                              </div>
                          </div>

                          <div className="swiper-slide">
                              <div className="item">
                                  <h3 className="mb-3">{t("zgjidhjeInovative")}</h3>
                                  <h4 className="mb-3">{t("programiNdryshon")}</h4>
                                  <p>{t("ngaFillimi")}</p>
                              </div>
                          </div>

                          <div className="swiper-slide">
                              <div className="item">
                                  <h3 className="mb-3">{t("dizajnModern")}</h3>
                                  <h4 className="mb-3">{t("meTeGjithe")}</h4>
                                  <p>{t("eBukura")}</p>
                              </div>
                          </div>

                          <div className="swiper-slide">
                              <div className="item">
                                  <h3 className="mb-3">{t("asistence")}</h3>
                                  <h4 className="mb-3">{t("qellimiTjeter")}</h4>
                                  <p>{t("gjithashtuNese")}</p>
                              </div>
                          </div>
                      </div>
                      <div className="swiper-pagination"></div>
                  </div>
                  <div className="swiper-button-prev"></div>
                  <div className="swiper-button-next"></div>
              </Col>
          </Row>
      </Container>
  </section>
    )
}