import React from "react";
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from "react-bootstrap";
import { TbBrandNextjs } from "react-icons/tb";
import { FaReact } from "react-icons/fa";
import { FiDatabase } from "react-icons/fi";
import { CgDesignmodo } from "react-icons/cg";
import { FaWordpress } from "react-icons/fa";
import { MdBrandingWatermark } from "react-icons/md";
import { Link } from "react-router-dom";

export const SectionThree = () => {
  const { t } = useTranslation();
  return (
    <section id="services-list" className="services-list">
      <Container data-aos="fade-up">
        <div className="section-header">
          <h2>{t("shërbimet")}</h2>
        </div>
        <Row className="gy-5">
          <Col lg={4} md={6} className="service-item d-flex" data-aos="fade-up" data-aos-delay="100">
            <div
              style={{ marginRight: "10px" }}
              className="icon flex-shrink-0">
              <h1>
                <FaReact style={{ color: "#61DBFB" }} />
              </h1>
            </div>
            <div>
              <h4 className="title">
                <Link to="https://reactnative.dev/" className="stretched-link">
                  {t("reactNativeJS")}
                </Link>
              </h4>
              <p className="description">
                {t("gjuhaProgramuese")}
              </p>
            </div>
          </Col>
          <Col lg={4} md={6} className="service-item d-flex" data-aos="fade-up" data-aos-delay="200">
            <div
              style={{ marginRight: "10px" }}
              className="icon flex-shrink-0"
            >
              <h1>
                <TbBrandNextjs style={{ color: "#00000" }} />
              </h1>
            </div>
            <div>
              <h4 className="title">
                <Link to="https://nextjs.org/" className="stretched-link">
                  NextJS
                </Link>
              </h4>
              <p className="description">
                {t("perparesiaKryesore")}
              </p>
            </div>
          </Col>
          <Col lg={4} md={6} className="service-item d-flex" data-aos="fade-up" data-aos-delay="300">
            <div
              style={{ marginRight: "10px" }}
              className="icon flex-shrink-0"
            >
              <h1>
                <FiDatabase style={{ color: "#61DBFB" }} />
              </h1>
            </div>
            <div>
              <h4 className="title">
                <Link to="#" className="stretched-link">
                  Database Specialist
                </Link>
              </h4>
              <p className="description">
                {t("zgjidhjetDB")}
              </p>
            </div>
          </Col>
          <Col lg={4} md={6} className="service-item d-flex" data-aos="fade-up" data-aos-delay="400">
            <div
              style={{ marginRight: "10px" }}
              className="icon flex-shrink-0"
            >
              <CgDesignmodo style={{ color: "#15bfbc" }} />
            </div>
            <div>
              <h4 className="title">
                <Link to="#" className="stretched-link">
                  UI/UX Design
                </Link>
              </h4>
              <p className="description">
                {t("njeWebiBukur")}
              </p>
            </div>
          </Col>
          <Col lg={4} md={6} className="service-item d-flex" data-aos="fade-up" data-aos-delay="500">
            <div
              style={{ marginRight: "10px" }}
              className="icon flex-shrink-0"
            >
              <h1>
                <FaWordpress style={{ color: "#21759b" }} />
              </h1>
            </div>
            <div>
              <h4 className="title">
                <Link to="https://www.bluehost.com/blog/what-is-wordpress/?psafe_param=1&utm_campaign=affiliate-link_searchgenericpromo_PPC&utm_source=google&utm_medium=genericsearch&utm_affiliate=searchgenericpromo&irpid=101&clickid=P61C101S570N0B5578A2D4499E0000V261&pb=signup_searchgenericpromo&siteid=101&channelid=P61C101S570N0B5578A2D4499E0000V261&gclid=Cj0KCQjwmtGjBhDhARIsAEqfDEdb_Z7VAL88l_h-DtWR1qS-CrNSvTvTaw5Ey566blVD3r9MFAgQu0EaAiTtEALw_wcB&gclsrc=aw.ds" className="stretched-link">
                  Wordpress
                </Link>
              </h4>
              <p className="description">
                {t("eksperiencaWordpress")}
              </p>
            </div>
          </Col>
          <Col lg={4} md={6} className="service-item d-flex" data-aos="fade-up" data-aos-delay="600">
            <div
              style={{ marginRight: "10px" }}
              className="icon flex-shrink-0"
            >
              <h1>
                <MdBrandingWatermark style={{ color: "#21759b" }} />
              </h1>
            </div>
            <div>
              <h4 className="title">
                <Link to="#" className="stretched-link">
                  {t("brendimMarketing")}
                </Link>
              </h4>
              <p className="description">
                {t("ngaKrijimiLogeve")}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
};