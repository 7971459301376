import React from "react";
import LOGO from "../assets/img/FINALVISUALCOREWHITE.png";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from "react-bootstrap";
import locationIcon from "../assets/img/locationicon.png";
import L from "leaflet";
import { Link } from "react-router-dom";

export const Footer = () => {
  const { t } = useTranslation();
  const latitude = 42.653210;
  const longitude = 21.181700;
  const address = "Rruga Ndue Perleshi, Prishtine, Kosovo";

  return (
    <footer id="footer" className="footer">
      <div className="footer-content">
        <Container>
          <Row className="gy-4">
            <Col lg={5} md={12} className="footer-info">
              <Link to="index.html" className="logo d-flex align-items-center">
                <img src={LOGO} width="50px" alt="Logo" />
              </Link>
              <p>
                {t("rrethProjekteve")}
              </p>
              <div className="social-links d-flex  mt-3">
                <Link to="https://www.facebook.com/VisualCoreKosova" className="facebook">
                  <i className="bi bi-facebook"></i>
                </Link>
                {/* <Link to="#" className="instagram">
                  <i className="bi bi-instagram"></i>
                </Link> */}
                <Link to="https://www.linkedin.com/company/visualcoreks" className="linkedin">
                  <i className="bi bi-linkedin"></i>
                </Link>
              </div>
            </Col>

            <Col lg={3} md={12} className="footer-contact text-center text-md-start">
              <h4>{t("ContactUs")}</h4>
              <p>
                {t("rruga")} <br />
                {t("prishtine")}
                <br />
                {t("kosove")} <br />
                <br />
                <strong>{t("tel")}:</strong> 048 77 99 00
                <br />
                <strong>Email:</strong> info@visual-core.com
                <br />
              </p>
            </Col>

            <Col lg={4} md={12} className="footer-map">
              <MapContainer center={[latitude, longitude]} zoom={14} style={{ height: '300px' }}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution="Map data &copy; <Link to=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</Link> contributors"
                />
                <Marker position={[latitude, longitude]} icon={L.icon({ iconUrl: locationIcon, iconSize: [30, 30] })}>
                  <Popup>{address}</Popup>
                </Marker>
              </MapContainer>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-legal">
        <Container>
          <div className="copyright">
            &copy; {new Date().getFullYear()}{" "}
            <strong>
              <span>VisualCore</span>
            </strong>
            . All Rights Reserved
          </div>
        </Container>
      </div>
    </footer>
  );
};
