import React from "react";
import { Container, Row } from "react-bootstrap";
import { CustomCard } from "../../CustomCard";
import { useTranslation } from "react-i18next";
import Ngadhnjim from "../../../assets/img/NgadhnjimGashi.jpg"
import Sherif from "../../../assets/img/SherifGashi.jpg"
import Anda from "../../../assets/img/AndaKadriu.jpg"
import Kastriot from "../../../assets/img/KastriotRexhepi.jpg"
import Fabian from "../../../assets/img/FabianBerisha.png"
import ArberK from "../../../assets/img/ArberKadriu.png"
import TaulantM from "../../../assets/img/TaulandMerlaku.jpg"
import NotSet from "../../../assets/img/unknown.jpg"


const teamMember = [
    {
        name: 'Arber Kadriu',
        image: ArberK,
        titleKey: 'CEO',
    },
    {
        name: 'Ngadhnjim Gashi',
        image: Ngadhnjim,
        titleKey: 'CEO',
    },
    {
        name: 'Sherif Gashi ',
        image: Sherif,
        titleKey: 'shefKontabiliteti',
    },
    {
        name: 'Pranvera Kadriu',
        image: NotSet,
        titleKey: 'financiere',
    },
    {
        name: 'Kastriot Rexhepi',
        image: Kastriot,
        titleKey: 'Outsource',
    },
    {
        name: 'Fabian Berisha ',
        image: Fabian,
        titleKey: 'Outsource',
    },
    {
        name: 'Besnik Qehaja',
        image: NotSet,
        titleKey: 'Outsource',
    },
    {
        name: 'Taulant Merlaku',
        image: TaulantM,
        titleKey: 'zhvillues',
    },
    {
        name: 'Elmedin Hajdari',
        image: NotSet,
        titleKey: 'zhvillues',
    },
    {
        name: 'Rinor Gllareva',
        image: NotSet,
        titleKey: 'zhvillues',
    },
    // discuss with @arber before uncommenting this one
    // {
    //     name: 'Medina Shamolli',
    //     image: 'https://bootstrapmade.com/demo/templates/Dewi/assets/img/team/team-3.jpg',
    //     titleKey: 'Outsource',
    // },
]

export const OurTeam = () => {
    const { t } = useTranslation();
    return <>
        <section id="team" class="team section-bg">
            <Container data-aos="fade-up">
                <div className="section-header">
                    <h2>{t("ekipiJone")}</h2>
                </div>
                <Row>
                    {teamMember.map(member => {
                        return <CustomCard name={member.name} image={member.image} title={t(member.titleKey)}/>
                    })}
                </Row>
            </Container>
        </section>
    </>
}