import React, { useEffect, useRef } from "react";
import bgImage from "../../assets/img/programming-code.jpg";
import { TiTickOutline } from "react-icons/ti";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import SectionOne from "../../components/pageSection/home/SectionOne";
import { SectionTwo } from "../../components/pageSection/home/SectionTwo";
import { SectionThree } from "../../components/pageSection/home/SectionThree";
import { SectionFour } from "../../components/pageSection/home/SectionFour";
import { SectionFive } from "../../components/pageSection/home/SectionFive";

export const Home = () => {
  const { t } = useTranslation();
  const mainRef = useRef(null);

  useEffect(() => {
    if (mainRef.current) {
      mainRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <main id="main" ref={mainRef}>
        <SectionOne />
        <SectionTwo />
        <SectionThree />
        <SectionFour />
        {/* <SectionFive/> */}
      </main>
      <a
        href="#"
        className="scroll-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
      {/* <div id="preloader"></div> */}
    </>
  );
};
