import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { TopComponent } from "../../components/TopComponents";
import { OurTeam } from "../../components/pageSection/about/ourTeam";
import { useTranslation } from 'react-i18next';
import HelloSection from "../../components/pageSection/about/helloSection";
import { WhyUs } from "../../components/pageSection/about/whyUs";
import { Container, Row, Col } from "react-bootstrap";
import backgroundImage from "../../assets/img/hero-bg.jpg";

export const About = () => {
  const { t } = useTranslation();
  const mainRef = useRef(null);

  useEffect(() => {
    if (mainRef.current) {
      mainRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);


  return (
    <>
      <main id="main" ref={mainRef}>
        <TopComponent title={t("perNe")} backgroundImage={backgroundImage} />
        <HelloSection />
        <WhyUs />
        {/*  TODO: check if I should include this section */}
        {/* <section id="call-to-action" className="call-to-action">
          <Container data-aos="fade-up">
            <Row className="justify-content-center">
              <Col lg={6} className="text-center">
                <h3>Ut fugiat aliquam aut non</h3>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur. Excepteur sint
                  occaecat cupidatat non proident.
                </p>
                <a className="cta-btn" href="#">
                  Call To Action
                </a>
              </Col>
            </Row>
          </Container>
        </section> */}
        <section id="about" className="about">
          <OurTeam />
        </section>
      </main>
    </>
  );
};
