import React, { useEffect, useState } from 'react';
import VisualLogo from "../assets/img/FINALVISUALCOREWHITE.png";
import i18n from "../i18n";
import { useTranslation, initReactI18next } from 'react-i18next';
import { ChangeButton } from "./LanguageChange/changeButton";
import { Link } from "react-router-dom";

export const Header = () => {
  const { t } = useTranslation();

  const [scroll, setScroll] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };


    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className={scroll ? 'header scrolled' : 'header'}>
      <div className="logo">
      <Link to="/" >
      <img src={VisualLogo} width="100" alt="Logo" />
        </Link>
      </div>
      <nav className={`navbar ${isMenuOpen ? 'open' : ''}`}>
        <div className="hamburger" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul className={`menu ${isMenuOpen ? 'open' : ''}`}>
          <li onClick={closeMenu}><Link  to="/">{t("ballina")}</Link></li>
          <li onClick={closeMenu}><Link to="/about">{t("perNe")}</Link></li>
          <li onClick={closeMenu}><Link to="/services">{t("shërbimet")}</Link></li>
          <li onClick={closeMenu}><Link to="/portfolio">{t("projektetDhePartneret")}</Link></li>
          <li onClick={closeMenu}><Link to="/contact">{t("kontakti")}</Link></li>
          <li style={{ marginTop: '4px' }}><ChangeButton /></li>
        </ul>
      </nav>
    </header>
  );
};

