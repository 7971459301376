import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./views/home/Home";
import { About } from "./views/about/About";
import { Blog } from "./views/blog/Blog";
import { Contact } from "./views/contact/Contact";
import { Portofolio } from "./views/portofolio/Portofolio";
import { Service } from "./views/service/Services";
import { Team } from "./views/team/Team";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import i18n from "./i18n";
import { I18nextProvider } from 'react-i18next';
import Project from "./views/portofolio/Project";

function App() {
  return (
    <I18nextProvider i18n={i18n}>

    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/portfolio" element={<Portofolio />} />
        <Route path="/services" element={<Service />} />
        <Route path="/team" element={<Team />} />
        <Route path="/project/:id" element={<Project />} />
      </Routes>

      <Footer />
    </BrowserRouter>
    </I18nextProvider>
  );
}
export default App;
