import React, { useEffect, useRef } from "react";
import { TopComponent } from "../../components/TopComponents";
import { Projects } from "../../components/pageSection/portofolio/Projects";
import { Partners } from "../../components/pageSection/portofolio/Partners";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import backgroundImage from "../../assets/img/hero-bg.jpg";

export const Portofolio = ({ id }) => {
  const { t } = useTranslation();
  const mainRef = useRef(null);

  useEffect(() => {
    if (mainRef.current) {
      mainRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <main id="main" ref={mainRef}>
      <TopComponent
        title={t("projektetDhePartneret")}
        backgroundImage={backgroundImage}
      />
      <section id="portfolio" className="portfolio">
        <div className="section-header">
          <h2>{t("projektet")}</h2>
        </div>
        <Projects />
        <div className="section-header">
          <h2>{t("partneret")}</h2>
        </div>
        <Partners />
      </section>
    </main>
  );
};
