import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
import sq from '../src/locales/sq/translation.json';
import de from '../src/locales/de/translation.json';
import en from '../src/locales/en/translation.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      sq: { translation: sq },
      en: { translation: en },
      de: { translation: de },
    },
    lng: 'sq', 
    fallbackLng: 'sq', 
    interpolation: {
      escapeValue: false, 
    },
  });
export default i18n;