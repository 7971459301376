import LPPAImage from '../assets/img/portfolio/LPPA.png';
import kulmiKs from "../assets/img/portfolio/kulmiks.png"
import yfe from "../assets/img/portfolio/yfe.png"
import luxury from "../assets/img/portfolio/luxury.png"
import arberia from "../assets/img/portfolio/arberia.png"
import medical from "../assets/img/portfolio/medical.png"
import ontrine from "../assets/img/portfolio/ontrine.png"
import gege from "../assets/img/portfolio/gege.png"
import seronera from "../assets/img/portfolio/Seronera.png"
import barileva from "../assets/img/portfolio/barileva.png"


export const portofolioItems = [
    {
        id: 1,
        title: 'Kulmi-ks.com',
        description: 'kulmi_description',
        img: kulmiKs,
        link: 'https://www.kulmi-ks.com',
    },
    {
        id: 2,
        title: 'YFE',
        description: 'YFE_description',
        img: yfe,
        link: 'https://www.yf-europa.de'
        // link: 'https://www.komentar-lppa.net'

    },
    {
        id: 3,
        title: 'LPPA',
        description: 'LPPA_description',
        img: LPPAImage,
        link: 'https://www.komentar-lppa.net'
    },
    {
        id: 4,
        title: 'Luxury Estate',
        description: 'LuxuryEstate_description',
        img: luxury,
        link: 'https://www.luxuryestate-ks.com'
    },
    {
        id: 5,
        title: 'Arberia-Kos',
        description: 'ArberiaKos_description',
        img: arberia,
        link: 'https://www.arberia-kos.com'
    },
    {
        id: 6,
        title: 'MA-Europe',
        description: 'MAEurope_description',
        img: medical,
        link: 'https://www.ma-europe.de'
    },
    {
        id: 7,
        title: 'Ontrine',
        description: 'Ontrine_description',
        img: ontrine,
        link: 'https://www.ontrine.com',
    },
    {
        id: 8,
        title: 'Gegë',
        description: 'Gege_description',
        img: gege,
        link: 'https://gege-properpizza.com',
    },
    {
        id: 9,
        title: 'Seronera',
        description: 'Seronera_description',
        img: seronera,
        link: 'https://seronera.site/',
    },
    {
        id: 10,
        title: 'Barileva Turist',
        description: 'Barileva_description',
        img: barileva,
        link: 'http://barilevaturist.com/',
    }
]