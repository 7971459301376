import React from 'react';
import factimg from "../../../assets/img/5.jpg"
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';

export const WhyUs = () => {
  const { t } = useTranslation();

  return (
    <section id="facts">
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <div className="section-header">
            <h2>{t("pseNe")}</h2>
          </div>
          <p>
            {t("jemiEkip")}
          </p>
        </header>

        <div className="row counters">
          {/* <div className="col-lg-4 col-6 text-center">
            <CountUp start={0} end={232} duration={2} />
            <p>{t("klientet")}</p>
          </div> */}

          <div className="col-lg-4 col-6 text-center">
            <CountUp start={0} end={10} duration={4} />
            <p>{t("projektet")}</p>
          </div>

          <div className="col-lg-4 col-6 text-center">
            <CountUp start={0} end={1364} duration={2} />
            <p>{t("oretMbeshtetjes")}</p>
          </div>

          <div className="col-lg-3 col-6 text-center">
            <CountUp start={0} end={14} duration={4} />
            <p>{t("punetoretZellshem")}</p>
          </div>
        </div>

        <div className="facts-img">
          <img src={factimg }alt="" className="img-fluid" />
        </div>
      </div>
    </section>
  );
}

