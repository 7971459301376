import React from "react";
import { Col } from "react-bootstrap"; 
import { Link } from "react-router-dom";

// TODO: check if we need social media links
export const CustomCard = ({name, title, image}) => {
    return <>
        <Col lg={3} md={6} className="d-flex align-items-stretch">
            <div className="member" data-aos="fade-up" data-aos-delay="100">
                <div className="member-img fixed-height-card">
                    <img src={image} className="img-fluid" alt="" />
                    {/* <div className="social">
                        <Link to=""><i className="bi bi-twitter"></i></Link>
                        <Link to=""><i className="bi bi-facebook"></i></Link>
                        <Link to=""><i className="bi bi-instagram"></i></Link>
                        <Link to=""><i className="bi bi-linkedin"></i></Link>
                    </div> */}
                </div>
                <div className="member-info">
                    <h4>{name}</h4>
                    <span>{title}</span>
                </div>
            </div>
        </Col>
    </>
}