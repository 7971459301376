import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { TopComponent } from "../../components/TopComponents";
import { Container, Row, Col } from "react-bootstrap";
import backgroundImage from "../../assets/img/hero-bg.jpg";
import { useTranslation } from 'react-i18next';


export const Contact = () => {
  const { t } = useTranslation();
  const mainRef = useRef(null);

  useEffect(() => {
    if (mainRef.current) {
      mainRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <main id="main" ref={mainRef}>
        <TopComponent title={t("kontakti")} backgroundImage={backgroundImage} />
        <section id="contact" className="contact">
          <Container className="position-relative" data-aos="fade-up">
            <Row className="gy-4 d-flex justify-content-end">
              <Col lg={5} data-aos="fade-up" data-aos-delay="100">
                <div className="info-item d-flex">
                  <i className="bi bi-geo-alt flex-shrink-0"></i>
                  <div>
                    <h4>{t("lokacioni")}</h4>
                    <p>{t("rruga")}</p>
                  </div>
                </div>
                <div className="info-item d-flex">
                  <i className="bi bi-envelope flex-shrink-0"></i>
                  <div>
                    <h4>Email:</h4>
                    <p>info@visual-core.com</p>
                  </div>
                </div>
                <div className="info-item d-flex">
                  <i className="bi bi-phone flex-shrink-0"></i>
                  <div>
                    <h4>{t("tel")}</h4>
                    <p>+383 49 700 xxx</p>
                  </div>
                </div>
              </Col>

              <Col lg={6} data-aos="fade-up" data-aos-delay="250">
                <form
                  action="forms/contact.php"
                  method="post"
                  role="form"
                  className="php-email-form"
                >
                  <Row>
                    <Col md={6} className="form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder={t("emrijuaj")}
                        required
                      />
                    </Col>
                    <Col md={6} className="form-group mt-3 mt-md-0">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder={t("emailjuaj")}
                        required
                      />
                    </Col>
                  </Row>
                  <div className="form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder={t("subjekti")}
                      required
                    />
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control"
                      name="message"
                      rows="5"
                      placeholder={t("mesazhi")}
                      required
                    ></textarea>
                  </div>
                  <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit">{t("dergomsg")}</button>
                  </div>
                </form>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  );
};
